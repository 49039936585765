import { createApp } from 'vue'
import App from './App.vue'
import router, { nprogress } from './router'
import store from './store'
import 'nprogress/nprogress.css'
import '@/assets/css/style.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { 
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons'

import {
  faCopy
} from '@fortawesome/free-solid-svg-icons'

import { 
  faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
  faCopy,
  faCopyRegular
)


createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(store)
  .use(nprogress)
  .mount('#app')
  