<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer v-show="isLoading" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Header,
  },
  computed: {
    isLoading() {
      return this.$route.meta.isLoading
    },
  },
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
