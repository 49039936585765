import { createRouter, createWebHistory } from 'vue-router'
import VueNprogress from 'vue-nprogress'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/LandingPage.vue')
  },
  {
    path: '/wait-list',
    name: 'waitList',
    component: () => import('@/views/WaitList.vue')
  },
  {
    path: '/check-your-rank',
    name: 'finalScreen',
    component: () => import('@/views/FinalScreen.vue')
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: () => import('@/views/AboutUs.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'termsConditions',
    component: () => import('@/views/TermsConditions.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy.vue')
  },
  {
    path: '/blogs',
    name: 'blogsList',
    component: () => import('@/views/BlogsList.vue')
  },
  {
    path: '/blogs/:slug', 
    name: 'blogContent',
    component: () => import('@/views/BlogsContent.vue'),
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export const nprogress = new VueNprogress({
  showSpinner: false
})

router.beforeEach((to) => {
  to.meta.isLoading = true
  nprogress.start()
})


router.afterEach((to) => {
  setTimeout(() => {
    to.meta.isLoading = false
    nprogress.done()
  }, 300);
})

export default router
